.navbarcolor {
  background-color: black;
}
.logo_height {
  height: 70%;
  width: 80%;
}

@media only screen and (max-width: 762px) {
  .logo_height {
    height: 40%;
    width: 50%;
  }
  .navbarcolor {
    height: 73px;
  }
}
