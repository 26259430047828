/* s=scheduled */
.sheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  /* font-variant: small-caps; */
  color: #0129fa;
}

.timeslotBtn {
  background: #ffffff;
  border: 1px solid #cacaca;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  color: #cacaca;
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
}
.timeslotBtn2 {
  background: #ffffff;
  border: 1px solid black;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  color: #0129fa;
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
}

.timeslotBtn:hover {
  color: #0129fa;
}

.disable-div {
  pointer-events: none;
}
