/* reviewDetails = rds */

.rds-h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 174.4%;
  display: flex;
  align-items: center;
  color: #0129fa;
}

.rds-h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 174.4%;
  color: #333333;
}
.with-arrow {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.with-arrow:hover::before {
  content: "➔"; /* Arrow icon (you can replace this with the actual arrow icon you want to use) */
  margin-right: 5px; /* Adjust as needed for spacing */
}

.rds-h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 174.4%;
  /* or 24px */

  display: flex;
  align-items: center;

  /* DArk Text */

  color: #999999;
}

.rds-h4 {
  margin-left: 7%;
}

.go-back-btn {
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  /* padding: 10px 20px; */
  border: none;
  margin-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 10px;
  height: 25px;
  width: 100px;
}
.pp_text{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.p-div {
  text-align: center;
}
/* .go-back-link {
  margin-top: 15px; 
  text-decoration: none; 
} */
