/* p=profile */

.pcardheadings {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #0129fa;
}

.pInputHeadings {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.pStaricColor {
  color: #ef4050;
}

textarea {
  height: 208px !important;
}

.del-box {
  background-color: "";
}

.memberBtn {
  background-color: "#fff" !important;
  color: "#0129FA" !important;
  margin-top: "30px" !important;
}

.memberBtn:hover {
  background-color: #0129fa;
  color: #fff;
}

.bin_icon_align {
  margin-top: 06px;
  text-align: center;
  float: right;
}

.already_exist_user_text {
  color: red;
  font-size: 16px;
  font-weight: 500;
}

.already_exist_card {
  border-color: red;
}

.already_exist_user_text1 {
  color: rgb(164, 158, 158);
  font-size: 14px;
  font-weight: 400;
}
