/* as = applicationSubmitted  */

/* .asTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 25px;
text-transform: uppercase;
color: #0129FA;
}

.asSubTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 24px;
text-align: center;
color: #333333;
} */
.allCardsPadding {
}

@media only screen and (max-width: 762px) {
  .asTitle {
    text-align: center !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #000000;
  }
  .asSubTitle {
    text-align: center !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 24px;
    color: #333333;
  }
  .asCardAlign {
    text-align: center !important;
  }
  .allCardsPadding {
    padding-left: 10px;
    padding-right: 10px;
  }
}
