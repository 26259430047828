/* t=tours */

.tourImg {
  position: fixed;
  bottom: 0;
  z-index: -10;
  width: 25vw;
}

/* .tr-maindiv{
  height: 100vh;
  overflow-y:hidden ;
  position: fixed;
} */
.activeNav {
  /* background-color: #35d7a1 !important ;
  color: #fff !important;
  border: 1px solid #35d7a1; */
  background-color: #0129fa !important;
  color: #fff !important;
  border: 1px solid #0129fa !important;
}

.doneText {
  /* color: #35d7a1 !important; */
  color: #0129fa !important;
}

.ttitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #0129fa;
  flex: none;
  order: 1;
  flex-grow: 0;
}

/* .greencolor {
  background-color: #00bcae;
  align-items: center;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
} */

/* default  */
.roundedCircle {
  /* border: 1px solid #00bcae; */
  border: 1px solid #cacaca !important;

  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.rounded-btn-text {
  color: #cacaca !important;
}

.dottedlin {
  /* border-left: 2px dashed #00bcae; */
  border-left: 2px dashed #cacaca !important;

  /* height: 40px; */
  height: 10px;
  margin-left: 20px;
}

/* active  */

.roundedCircleActive {
  /* border: 1px solid #00bcae; */
  /* border: 1px solid #484848; */
  border: 1px solid #0129fa;
  background-color: none !important;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.rounded-btn-text-Active {
  /* color: #484848; */
  color: #0129fa;
  /* #828282; */
}

.dottedlinActive {
  /* border-left: 2px dashed #00bcae; */
  /* border-left: 2px dashed #484848; */
  border-left: 2px dashed #cacaca;

  height: 10px;
  margin-left: 20px;
}

.dottedlinDone {
  /* border-left: 2px dashed #00bcae; */
  border-left: 2px dashed #35d7a1;
  height: 10px;
  margin-left: 20px;
}

.text-muted {
  font-size: 16px;
  font-weight: 600;
}

/* @media only screen and (max-width: 500px) { */
@media only screen and (max-width: 762px) {
  .tr-maindiv {
    display: none;
  }
}
