/* p=profile */

.pcardheadings {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #0129fa;
}

.pInputHeadings {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.pStaricColor {
  color: #ef4050;
}

textarea {
  height: 208px !important;
}

.dropzone {
  min-height: 230px;
  border: 2px dashed gray;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;

  /* .dz-message {
    font-size: 24px;
    width: 100%;
  } */
}
.drop_logo_center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.dropImg_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: rgba(51, 51, 51, 0.4);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.dropImg_h11 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: black;
  text-decoration: underline;
}

.dropImg_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: rgba(51, 51, 51, 0.4);

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.dropImg_h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Official Black */

  color: #333333;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.dropzone {
  border: 1px solid #eaeaea;
  border-radius: 4px;
}
