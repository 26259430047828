/* rv = reschedule verification  */

.rv_card {
  /* justify-content: center; */
  /* align-items: center; */
  background-color: #d3d4d7 !important;
}

.rv_send_code {
  /* align-items: right !important; */
  float: right;
}

.rv_card_title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
}

.vr_email_font {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  display: inline-flex;
}

.rv_card_user {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
}
.rv_btn_inline {
  /* display: flex; */
  align-items: center;
  justify-items: center !important;
  display: inline-flex;
}
.rv_btn_text_space {
  margin-left: 10px;
}

.allbuttonfont {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
}
