/* wc=wellcome */
.wctitle {
  font-family: "Montserrat";
  /* font-weight: 500;
    font-size: 30px; */
  font-weight: 500;
  font-size: 30px;
  /* font-size: 26px; */
  color: #000000;
}
.wcsubtitle {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.wccardtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.wccardsubtitle {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.wcbutton {
  color: #35d7a1;
  border-color: #35d7a1;
  font-weight: 700;
  font-size: 18px;
  padding: 16px, 10px, 16px, 10px;
  border-radius: 10px;
  height: 65px;
}

.wcImageCol {
  display: flex;
  justify-content: center;
}
.wcImage {
  width: 100%;
  height: 100px;
}

.wecard {
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* box-sizing: border-box; */
}

.wcfooterimg {
  width: 100%;

  height: 206px;
}

.wcimgashow {
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  /* align-items: flex-end; */
  background-image: url("../../../images/footers/wcfooter1.svg");
  /* background-size:  300px 240px;*/
  background-repeat: no-repeat;
  /* -webkit-background-size:cover; */
  /* -moz-background-size:cover; */
  /* -o-background-size:cover; */
  /* background-size:cover; */
  /* background-position:cover;  */
  /* background-size: 100%; */
}
.link {
  text-decoration: none !important;
}

.ft-wc-main-box {
  position: relative;
}

.wc-bottom-img {
  position: fixed;
  bottom: 0;
  z-index: -10;
  width: 100%;
}

.disabled-card-h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  opacity: 0.5;
  color: #000000;
}
.disabled-card-h2 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  opacity: 0.5;
  color: rgba(0, 0, 0, 0.5);
}

.wcImagedisabled {
  opacity: 0.5;
  width: 100%;
  height: 100px;
}

.wc-unavalibale-Message {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #000000;
}

.ftCss {
  position: fixed;
  bottom: 0;
  z-index: -10;
  width: 100%;
  background-color: black;
  height: 50px;
  align-items: center;
}

.ftCss1 {
  /* margin-top: 10px; */
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  color: white;
  text-align: center;
  align-items: center;
}

.wcimgdiv2 {
  width: 100%;
  bottom: 10;
  z-index: -10;
}
.wc-bottom-img2 {
  position: fixed;
  bottom: 0;
  z-index: -10;
  width: 100%;
  height: 40vh;
}

/* Media Queries */

@media only screen and (max-width: 786px) {
  .wctitle {
    font-size: 22px;
    line-height: 26.82px;
  }

  .wcsubtitle {
    font-size: 16px;
    line-height: 19.5px;
    margin-top: 10px;
  }
}

/* @media only screen and (max-width: 500px) { */
@media only screen and (max-width: 762px) {
  /* .wcimgashow {
    background-image: url("../../../images/footers/footer-mobile.png");
  } */

  .wc-bottom-img {
    position: relative;
  }
}
/* @media only screen and (max-width: 500px) { */
@media only screen and (max-width: 600px) {
  .ftCss1 {
    font-size: 8px;
  }
}
