/* e=email */
.ebutton {
  background: #0129fa;
  font-weight: 500;
  font-size: 18px;
}

.ecardheadings {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  color: #0129fa;
}

.ecardheadings2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}

.emainheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-transform: capitalize;
  color: #333333;
}

.mailmainpheading2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #999999;
}

.mailmainpheading3 {
  margin-top: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #999999;
}

.emailinputs {
  height: 56px;
}

.ebgcolor {
  /* background: #F5F6F9; */
  
}
.form-card {
  border-radius: 8px !important;
  border: none !important;
}

.emainheadingMobView {
  display: none;
}

/* @media only screen and (max-width: 500px) { */
@media only screen and (max-width: 762px) {
  .emainheadingMobView {
    display: block;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
  }
  .ebgcolor {
    padding: 0 !important;
  }

  .form-card {
    padding: 0 !important;
    background-color: transparent !important;
    border: none !important;
  }

  .ebutton {
    width: 50% !important;
  }
}
