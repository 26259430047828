.inputerror {
  color: #ef4050;
  font-size: 12px;
  padding: 3px;
  display: none;
}
.formInput {
  padding: 1px;
}
.forminputsize {
  height: 56px;
  /* border: 1px solid #000000; */
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.inputs:invalid[focused="true"] + .inputerror {
  display: block;
}

.inputs:invalid[focused="true"] {
  border: 1px solid red;
}
/* invalid[focused="ture"] */

.modelImg {
  display: flex;
  justify-content: center;
}

.modelBody {
  text-align: center;
}

.modelBtn1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  height: 48px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.modelBtn2 {
  background: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  border: none;
  height: 48px;
}

.model-body {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}

.forminputsize::placeholder {
  color: #c1bfbf !important; /* Adjust the color as needed */
}
