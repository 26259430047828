.mainContent {
  /* overflow-x: hidden; */
}

.bottomImg {
  display: none;
}

.btn-p {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .mobile-background{
  display: none;
} */

/* @media only screen and (max-width: 500px) { */
@media only screen and (max-width: 762px) {
  .bottomImg {
    display: block;
    width: 100%;
    /* position: fixed; */
    /* position: fixed; */
    bottom: 0vh !important;
    /* top: 100vh !important; */
    transform: translateY(80%);

    /* z-index: -1; */
  }
}
