/* e=email */

.ecardheadings {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0129fa !important;
}
.ecardheadingss {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.eresendlink {
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.ecardheadings2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5) !important;
  opacity: 0.7;
}
.e_input {
  border: 1px solid #000000 !important;
  border-radius: 5px !important;
}

.emainheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}
.mailmainpheading2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

.emailinputs {
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.ehome {
  background-color: #000000;
}

.ebutton1 {
  background-color: #000000 !important;
  font-weight: 500;
  font-size: 18px;
}
.form-card {
  border-radius: 8px !important;
  border: none !important;
}

/* @media only screen and (max-width: 500px) { */
@media only screen and (max-width: 762px) {
  .ebgcolor {
    padding: 0 !important;
  }

  .form-card {
    padding: 0 !important;
    background-color: transparent !important;
    border: none !important;
  }
  .ebutton1 {
    background-color: #000000 !important;
    font-weight: 500;
    font-size: 14px !important;
  }
  .ecardheadings {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #0129fa !important;
  }
  .ecardheadingss {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #000000;
  }
  .ecardheadings2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5) !important;
    opacity: 0.7;
  }
  .emainheading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 20px !important;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .mailmainpheading2 {
    display: none !important;
  }
}
