/* rs = reschdule */

.rs_left_card {
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.32);
  border-radius: 0px 20px 20px 0px;
}
.rs_main_div {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.rs_card_h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: -0.01em;
  color: rgba(0, 0, 0, 0.5);
}
.rs_card_h11 {
  text-align: right;
}

.rs_card_h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #000000;
}

.rs_card_h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #000000;
}

.rs_card_h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #0129fa;
}

.rs_button_label1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer  
}

.rs_img_h {
  /* height: 80vh; */
  /* width: 48vw; */
  height: 100vh;
  width: 100%;
  overflow: hidden !important;
}

.rs_justify {
  display: flex;
  align-items: right;
  justify-content: flex-end;
  text-align: right;
  /* justify-content: flex-end; */
  /* justify-content: space-between; */
}
