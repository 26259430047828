.mainContent {
  /* overflow-x: hidden; */
}
.fullHight {
  min-height: 100vh;
}
.mainContainer {
  min-height: 100vh;
  background-color: #f4f4f4;
}
.icon-box {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  /* background-color: none; */
  background-color: #ffffff;
  height: 36px;
  width: 36px;
  padding: 5px 6px 5px 6px;
  border-radius: 50px;
  float: right;
}

.icon-box a {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 4px 4px;
}

.icon-box a span {
  width: 20px;
  height: 20px;
}

.btn-p {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .mobile-background{
  display: none;
} */

/* @media only screen and (max-width: 500px) {
  .mobile-background {
    background-image: url("../../../images/footers/footer-mobile.png");
    background-position: center;
    width: 100vw;
    height: 80px;
    background-repeat: no-repeat;
  }
} */
