/* reviewDetails = rds */

.rds-h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 174.4%;
  display: flex;
  align-items: center;
  color: #0129fa;
}

.rds-h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 174.4%;
  color: #333333;
}

.rds-h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 174.4%;
  /* or 24px */

  display: flex;
  align-items: center;

  /* DArk Text */

  color: #999999;
}

.rds-h4 {
  margin-left: 7%;
}
