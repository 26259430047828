/* s=scheduled */
.sheading {
  /* font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0129fa !important; */
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0129fa !important;
}

.sheading3 {
  /* font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #c2c1c1; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Decline */

  color: #ef4050;
}

.timeslotBtn {
  background: #ffffff;
  border: 1px solid #cacaca;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  color: #cacaca;
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
}
.timeslotBtn2 {
  background: #ffffff;
  border: 1px solid black;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
}

/* .timeslotBtn:hover {
  color: #0129FA;
} */

.checkLabel {
  display: flex;
  flex: auto;
  vertical-align: middle;
  align-items: center;
  justify-content: left;
  text-align: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 56px;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.dayPassCalenderStyle {
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  border: none !important;
}

.form-card .react-calendar__tile:disabled {
  color: #1010104d;
}

.form-card .react-calendar__tile {
  color: #000000;
}

input[type="radio"]:checked + label {
  background-color: none;
  border: 1px solid #000000;
  color: #000000;
}

.InputGroup {
  display: flex;
  height: 56px;
  width: 100%;
  border: none;
  border-radius: 5px;
  /* background-color: #cacaca; */
}
input[type="radio"] {
  visibility: hidden; /* 1 */
  height: 0; /* 2 */
  width: 0; /* 2 */
  background-color: #999999;
}

@media only screen and (max-width: 762px) {
  .sheading {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #0129fa !important;
  }
}
