/* p=profile */

.pcardheadings {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  color: #0129fa;
}

.pButton {
  /* color: #0129fa;
  border-color: #0129fa; */

  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);

  font-weight: 500;
  font-size: 18px;
}
.pInputHeadings {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.pStaricColor {
  color: #ef4050;
}
