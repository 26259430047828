.mainContent {
  /* overflow-x: hidden; */
}

.icon-box {
  display: flex;
  justify-content: flex-end;
  background-color: none;
}



.icon-box a {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 4px 4px;
}

.icon-box a span {
  width: 20px;
  height: 20px;
}

.btn-p {
  /* display: flex; */
  /* justify-content: flex-end; */
  /* align-items: center; */
}

/* .mobile-background{
  display: none;
} */

/* @media only screen and (max-width: 500px) {
  .mobile-background {
    background-image: url("../../../images/footers/footer-mobile.png");
    background-position: center;
    width: 100%;
    height: 80px;
    background-repeat: no-repeat;
  }
} */


/* i used this revert for scroll issue */
.scroller {
}

@media only screen and (min-width: 375px) {
  .scroller {
    overflow-x: hidden;
    min-height: 100vh;
  }
}
