/* t=tours */

.member_Nav_Img {
  position: fixed;
  bottom: 0;
  z-index: -10;
  /* width: 100%; */
  width: 18vw;
  margin-left: 4%;
}

.tr-maindiv {
  position: fixed;
}
.activeNav {
  background-color: #0129fa !important;
  color: #fff !important;
  border: 1px solid #0129fa !important;
}

.doneText {
  color: #0129fa !important;
}

.ttitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #0129fa;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.roundedCircle {
  border: 1px solid #cacaca !important;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.rounded-btn-text {
  color: #cacaca !important;
}

.dottedlin {
  border-left: 2px dashed #cacaca !important;
  height: 10px;
  margin-left: 20px;
}

.roundedCircleActive {
  background-color: none !important;
  border: 1px solid #0129fa;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.rounded-btn-text-Active {
  color: #0129fa;
}

.dottedlinActive {
  border-left: 2px dashed #cacaca;
  height: 10px;
  margin-left: 20px;
}

.dottedlinDone {
  border-left: 2px dashed #35d7a1;
  height: 10px;
  margin-left: 20px;
}

.text-muted {
  font-size: 16px;
  font-weight: 600;
}

/* @media only screen and (max-width: 500px) { */
@media only screen and (max-width: 762px) {
  .tr-maindiv {
    display: none;
  }
}
