/* t=tours */

.tourImg {
  /* position: fixed; */
  /* z-index: -10; */
  padding-top: 10px;
  width: 100%;
}

.tr-maindiv {
  position: fixed;
}
.simple_bar_height {
  max-height: 330px;
}
.activeNav {
  background-color: #0129fa !important;
  color: #fff !important;
  border: 1px solid #0129fa !important;
}

.doneText {
  color: #0129fa !important;
}

.ttitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #000000 !important;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.roundedCircle {
  border: 1px solid #cacaca !important;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}

.btn-circle {
  cursor: auto !important;
}

.rounded-btn-text {
  color: #cacaca !important;
}

.dottedlin {
  /* border-left: 2px dashed #00bcae; */
  border-left: 2px dashed #cacaca !important;

  height: 10px;
  margin-left: 20px;
}

/* active  */

.roundedCircleActive {
  background-color: none !important;
  border: 1px solid #0129fa;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.rounded-btn-text-Active {
  color: #0129fa;
}

.dottedlinActive {
  border-left: 2px dashed #cacaca;
  height: 10px;
  margin-left: 20px;
}

.dottedlinDone {
  /* border-left: 2px dashed #00bcae; */
  border-left: 2px dashed #0129fa !important;
  height: 10px;
  margin-left: 20px;
}

.text-muted {
  font-size: 16px;
  font-weight: 600;
}
.text-muted-colored {
  color: #0129fa;
  font-size: 16px;
  font-weight: 600;
}

/* 772px */
@media only screen and (max-width: 762px) {
  .tr-maindiv {
    display: none;
  }
}
/* @media only screen and (max-width: 500px) {
  .tr-maindiv {
    display: none;
  }
} */
