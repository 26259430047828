/* as = applicationSubmitted  */

.asTitle {
  text-align: left !important;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000 !important;
}

.asSubTitle {
  font-family: "Montserrat";
  font-size: 16px !important;
  line-height: 15px;
  text-align: left !important;
  color: #828282;
}

.asCardAlign {
  text-align: center;
}

.asSubtitle23 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: #828282;
}

@media only screen and (max-width: 762px) {
  .asTitle {
    text-align: center !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #000000;
  }
  .asSubTitle {
    text-align: center !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 24px;
    color: #333333;
  }
  .asCardAlign {
    text-align: center !important;
  }
}
