/* p=profile */

.pcardheadings {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0129fa !important;
}

.pButton {
  color: black;
  border-color: black;
  font-weight: 500;
  font-size: 18px;
}
.pi_Input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.pi_PhoneInput {
  border-radius: 5px;
}
.flag-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.arrow_small_screen {
  display: none;
  margin-right: 10px;
}
.pInputHeadings {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.01em;
  color: #333333;
}
.pStaricColor {
  color: #ef4050;
}

.react-tel-input .form-control {
  height: 56px;
  width: 100%;
}

@media only screen and (max-width: 762px) {
  .arrow_small_screen {
    display: block;
  }
  .pButton {
    /* this first line is for uper arrow */
    display: none;
    font-weight: 500;
    font-size: 14px !important;
  }
  .pcardheadings {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #0129fa !important;
    line-height: 22px !important;
    margin-top: 30px !important;
  }
  .pInputHeadings {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.01em;
    color: #333333;
    margin-top: 10px;
  }
}
